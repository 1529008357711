import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { toRoute } from "../../i18n";

const Guarantee = React.memo(() => {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <div className="guarantee text-center">
      <div className="container">

        <h2 className="d-md-none mb-3 text-header">{t("home:Register")}</h2>
        <Image src={asset('/images/home/guarantee.svg')} alt="guarantee" width="256" height="185" className="w-auto h-auto mb-3" />

        <h3 className="mb-2 text-menu-title">{t("home:Satisfaction Guaranteed")}</h3>
        <p className="text-address">{t("home:Cancel the service for a full refund up to a period of 30 days.")}</p>

        <div className="d-md-none">
          <Link to={toRoute("/register")} className="btn btn-primary registration">
            {t("home:Register")}
          </Link>
        </div>

      </div>
    </div>
  )

})

export default Guarantee