import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { usePostRegisterAllMutation } from "../../services/silverman.service";
import styles from './register.module.scss';

const RegisterHome = React.memo(() => {

  const { t } = useTranslation(['translation', 'home', 'register'])
  const [registerForm, setRegisterData] = useState({
    contact_type: "juristic",
    name: "",
    position: "",
    phone: "",
    email: "",
    project_name: "",
    province: "",
    project_type: "",
    unit: "",
  })
  const [register, { isLoading, isSuccess }] = usePostRegisterAllMutation()

  function handelInput(e) {
    setRegisterData({ ...registerForm, [e.target.name]: e.target.value })
  }

  async function submit(e) {
    e.preventDefault()

  //   await register(registerForm).unwrap()


  let bodyFormData = new FormData();
    bodyFormData.append('name', registerForm.name);
    bodyFormData.append('project_name', registerForm.project_name);
    bodyFormData.append('project_type', registerForm.project_type);
    bodyFormData.append('position', registerForm.position);
    bodyFormData.append('province', registerForm.province);
    bodyFormData.append('phone', registerForm.phone);
    bodyFormData.append('email', registerForm.email);
    bodyFormData.append('unit', registerForm.unit);
    bodyFormData.append('contact_type', registerForm.contact_type);
    await register(bodyFormData)
    
    setRegisterData({
      name: "",
      position: "",
      phone: "",
      email: "",
      project_name: "",
      province: "",
      project_type: "",
      unit: "",
    })

  }

  return (
    <div className={styles.register}>
      <div className="container">
        <div className="box">
          <h1 className='text-left register-title' dangerouslySetInnerHTML={{ __html: t("home:Register now for receiving free special benefits! <span>SPECIAL LIMITED OFFER</span>") }} />

          <p className='text-left'>{t("home:Please register for receiving more information from staff")}</p>
          <div className='register-home-form'>
          <form className="row text-left register-home-from" onSubmit={submit} id="register-home">
            <div className="col-md-6">
              <div className="field">
                <input type="text" name="name" id="name"
                  placeholder={t("home:Full Name")} required
                  onChange={handelInput}
                  value={registerForm.name} />
                <label htmlFor="name">{t("home:Full Name")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="phone" id="phone"
                  placeholder={t("home:Telephone no.")} required
                  onChange={handelInput}
                  value={registerForm.phone} />
                <label htmlFor="phone">{t("home:Telephone no.")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="email" id="email"
                  placeholder={t("home:Email")} required onChange={handelInput}
                  value={registerForm.email} />
                <label htmlFor="email">{t("home:Email")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="position" id="position"
                  placeholder={t("home:Position")} required onChange={handelInput}
                  value={registerForm.position} />
                <label htmlFor="position">{t("home:Position")}<span className='text-danger'>*</span></label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="field">
                <input type="text" name="project_name" id="project_name"
                  placeholder={t("home:Property Name")} required
                  onChange={handelInput}
                  value={registerForm.project_name} />
                <label htmlFor="project_name">{t("home:Property Name")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="project_type" id="project_type"
                  placeholder={t("home:Property Type")} required
                  onChange={handelInput}
                  value={registerForm.project_type} />
                <label htmlFor="project_type">{t("home:Property Type")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="unit" id="unit"
                  placeholder={t("home:Unit Amount")} required onChange={handelInput}
                  value={registerForm.unit} />
                <label htmlFor="unit">{t("home:Unit Amount")}<span className='text-danger'>*</span></label>
              </div>
              <div className="field">
                <input type="text" name="province" id="province"
                  placeholder={t("home:Province")} required onChange={handelInput}
                  value={registerForm.province} />
                <label htmlFor="province">{t("home:Province")}<span className='text-danger'>*</span></label>
              </div>
            </div>

            <div className="w-100 text-center submit mt-5">
              {isLoading && (
                <div className="mt-5 alert alert-warning" role="alert">
                  {t("home:Saving your information.")}
                </div>
              )}

              {isSuccess && (
                <div className="mt-5 alert alert-success" role="alert" dangerouslySetInnerHTML={{ __html: t("home:Thank you for Registering with Silverman.") }}>
                </div>
              )}

              {!isLoading && (
                <button type="submit" className="btn btn-register-home" disabled={isLoading}>
                  {t("home:Register")}
                </button>
              )}
            </div>
          </form>
          </div>

        </div>
      </div>
    </div>
  )

})

export default RegisterHome
