import React from 'react';
import { useTranslation } from "react-i18next";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";

const ReasonHome = React.memo(() => {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <>
      <div className="reason">
        <div className="container">

          <h2 className="text-center text-header mb-3 pt-4">{t('home:Why Silverman…Property management features and property management accounting software')}</h2>

          <div className="box">
            <div className="row flex-nowrap flex-md-wrap justify-content-stretch">

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-connection.svg')} alt="connection" width="98" height="98" />
                  <h3>{t('home:Strong Real Estate Network')}</h3>
                  <p>{t('home:To create the software that truly responds to user needs, Silverman collaborates with Thailand’s leading property management companies and real estate developers. We have built a strong network and have simplified the transferring information process between property management companies seamlessly (However, this will require consent between the companies only).')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-feature.svg')} alt="feature" width="98" height="98" />
                  <h3>{t('home:New Features at No Cost***')}</h3>
                  <p>{t('home:We consistently develop new features to meet our user needs. Everyone in the property that uses Silverman application will enjoy an endless list of new features at no cost. (***Only Features that developed by Silverman. Does NOT include features that need to be connected to  Silverman partners, which may have additional cost.)')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-experience.svg')} alt="experience" width="98" height="98" />
                  <h3>{t('home:Experience and Expertise')}</h3>
                  <p>{t('home:For over 10 years, our teams have garnered experience in property management, so we are able to truly understand the business. With our expertise, we have developed the best PMS that can fulfill our user demands in every aspect.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-garantee.svg')} alt="garantee" width="98" height="98" />
                  <h3>{t('home:Flexibility')}</h3>
                  <p>{t('home:Our system is flexible, so users can choose to work the way they want. Users can work on Silverman in order to take care of their residents both online and offline simultaneously.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-technology.svg')} alt="technology" width="98" height="98" />
                  <h3>{t('home:Innovation')}</h3>
                  <p>{t('home:Our innovation will make property management tasks easy. The use of new technology and AI helps improving the management performance and efficiency.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason-api.svg')} alt="api" width="98" height="98" />
                  <h3>{t('home:Integration (API)')}</h3>
                  <p>{t('home:Silverman leverages APIs to connect with alliances from other systems in the future, as the way to endlessly extend more features of our service.')}</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="reason">
        <div className="container">

          <h2 className="text-center text-header mb-3">{t('home:Silverman - the best Property Management Solution')}</h2>

          <div className="box">
            <div className="row flex-nowrap flex-md-wrap justify-content-stretch">

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-fast.svg')} alt="fast" width="98" height="98" />
                  <h3>{t('home:Convenient and Timesaving')}</h3>
                  <p>{t('home:By using AI technology, Silverman simplifies the working process, reduces redundancy and increases efficiency for management in the properties.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-performance.svg')} alt="performance" width="98" height="98" />
                  <h3>{t('home:High Efficiency, Low Cost')}</h3>
                  <p>{t('home:Our comprehensive system covers all tasks in property management. Silverman helps lower the operation cost in the property by reducing unnecessary expenses, and minimizing workforce.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-learn.svg')} alt="learn" width="98" height="98" />
                  <h3>{t('home:Simple and Effective')}</h3>
                  <p>{t('home:At Silverman, we keep everything simple. We design effective software that is intuitive and straightforward; so it is easy and friendly for all users.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-safe.svg')} alt="safe" width="98" height="98" />
                  <h3>{t('home:Transparency & Risk Reduction')}</h3>
                  <p>{t('home:To gain trust from residents is very important to the property management team. Silverman understandably builds the operation system that helps prevent deception by allowing auditing in every activity to increase transparency.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-growup.svg')} alt="grow-up" width="98" height="98" />
                  <h3>{t('home:Scalability')}</h3>
                  <p>{t('home:Silverman is capable of handling business expansion. Management team can easily and effectively operate multiple properties by using a single platform.')}</p>
                </div>
              </div>

              <div className="col-auto col-md-4 mb-md-4 py-2">
                <div className="reason-box">
                  <Image src={asset('/images/home/reason1-support.svg')} alt="support" width="98" height="98" />
                  <h3>{t('home:Work simultaneously both on-site and support team')}</h3>
                  <p>{t('home:Silverman is cloud based system. We secure our data in trustworthy servers around the world. By using our new technology, we can increase the system stability and speed. Silverman can help improve the workflow by allowing  multiple departments to work simultaneously in real time, both on-site and support team.')}</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )

})

export default ReasonHome