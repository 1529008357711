import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Image from "../../components/image";
import { asset } from '../../helpers/dom.helper';
import { toRoute } from "../../i18n";
import { Media, MediaContextProvider } from "../../media";
import { useGetCustomerListQuery } from '../../services';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '10%'
}

const CustomerListDesktop = React.memo(() => {
  const { data, isLoading, error } = useGetCustomerListQuery()

  if (error) {
    return <div>{error.message}</div>
  } else if (isLoading) {
    return <div className="text-center py-4">Loading ...</div>
  }

  return (
    <Slider className="feedback-desktop" {...sliderSettings}>
      {data?.map((customer) => (
        <React.Fragment key={customer.id}>
          {
            customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/001_K_Jorn.jpg' ? <Image src={asset('/images/home/001_K_Jorn.webp')} alt={customer.name} className="image-cover" width={310} height={360}/>
            : customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/002_Pittaya.jpg' ? <Image src={asset('/images/home/002_Pittaya.webp')} alt={customer.name} className="image-cover" width={310} height={360}/>
            : customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/003_K_Kung.jpg' ? <Image src={asset('/images/home/003_K_Kung.webp')} alt={customer.name} className="image-cover" width={310} height={360} />
            : <Image src={customer.thumbnail} alt={customer.name} className="image-cover" width={310} height={360}/> 
          }

          <div className="pl-5">
            <div className="comment">
              <h2 className="text-menu-title mb-0">{customer.quote}</h2>
            </div>

            <div className="credit">
              <h3 className="text-caption mb-0">{customer.name}</h3>
              <p className="text-address">{customer.position}</p>
            </div>

            {/* <Link to={toRoute('/customer')}>
              <Image src={asset('/icon/arrow-right-blue.svg')} alt="arrow right blue" width="24" height="14" className="arrow" />
            </Link> */}
          </div>
        </React.Fragment>
      ))}
    </Slider>
  )
})

const CustomerListMobile = React.memo(() => {
  const { data, isLoading, error } = useGetCustomerListQuery()

  if (error) {
    return <div>{error.message}</div>
  } else if (isLoading) {
    return <div className="text-center">Loading ...</div>
  }

  return (
    <Slider className="feedback-mobile" {...sliderSettings} arrows={false} centerPadding={'0%'}>
      {data?.map((customer) => (
        <React.Fragment key={customer.id}>
          <div className="comment">
            <p>{customer.quote}</p>
          </div>

          <div className="credit">
            {
              customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/001_K_Jorn.jpg' ? <Image src={asset('/images/home/001_K_Jorn_mobile.webp')} alt={customer.name} className="image-cover" />
              : customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/002_Pittaya.jpg' ? <Image src={asset('/images/home/002_Pittaya_mobile.webp')} alt={customer.name} className="image-cover" />
              : customer.thumbnail === 'https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/customer-thumbnail/003_K_Kung.jpg' ? <Image src={asset('/images/home/003_K_Kung_mobile.webp')} alt={customer.name} className="image-cover" />
              : <Image src={customer.thumbnail} alt={customer.name} className="image-cover" /> 
            }

            <div className="name">
              <h3 className="text-menu-title">{customer.name}</h3>
              <p className="text-address">{customer.position}</p>
            </div>
          </div>

          <Link to={toRoute('/customer')}>
            <Image src={asset('/icon/arrow-right-blue.svg')} alt="arrow right blue" width="24" height="14" className="arrow" />
          </Link>
        </React.Fragment>
      ))}
    </Slider>
  )
})

export default function FeedbackHome() {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <div className="feedback-home">
      <div className="container">

        <h2 className="text-header text-center text-sm-left mb-4">{t("home:SEE WHY OVER 100 Projects USE Silverman DAILY")}</h2>

        <MediaContextProvider>
          <Media lessThan="md">
            <CustomerListMobile />
          </Media>

          <Media greaterThanOrEqual="md">
            <CustomerListDesktop />
          </Media>
        </MediaContextProvider>
      </div>
    </div>
  )

}
