import React from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import LazyLoad from "react-lazyload";
import { asset } from '../../helpers/dom.helper';
import { Media, MediaContextProvider } from "../../media";
import CustomerHome from "./customerHome";
import FeatureList from "./featureList";
import FeaturePlatformHome from "./featurePlatformHome";
import FeedbackHome from "./feedbackHome";
import Guarantee from "./guaranteeHome";
import HeaderHome from "./headerHome";
import styles from './home.module.scss';
import ReasonHome from "./reasonHome";
import RegisterHome from "./registerHome";
import PopupBanner from "./bannerPopup";
import HeaderHomeSilde from "./headerHomeSilde"

// import { Footer } from '../../layouts/footer';


export default function Home() {

  const { t } = useTranslation(['translation', 'home'])


  return (
    <>
      <Helmet>
        <title>{t('home:title')}</title>
        <meta name="description" content={t('home:metaDescription')} />
        <meta name="keywords" content={t('home:metaKeyWord')} />
        <style>{`
          :root {
            --home-bg-feature-acc-desktop: url(${asset('/images/home/feature-acc-bg-desktop.svg')});
            --home-bg-feature-app-desktop: url(${asset('/images/home/feature-app-bg-desktop.svg')});
            --home-bg-feedback-desktop: url(${asset('/images/home/feedback-bg-desktop.svg')});
            --icon-arrow-slide-left-dark: url(${asset('/icon/arrow-slide-left-dark.svg')});
            --icon-arrow-slide-next: url(${asset('/icon/arrow-slide-right.svg')});
          }
        `}</style>
      </Helmet>

      <div className={styles.home}>
        <LazyLoad once>
          {/* <HeaderHome /> */}
          <HeaderHomeSilde />
        </LazyLoad>
        
        <LazyLoad once>
          <FeaturePlatformHome />
        </LazyLoad>

        {/* PopupBanner */}
        <LazyLoad once>
          <PopupBanner />
        </LazyLoad>

        <LazyLoad once>
          <FeatureList />
        </LazyLoad>

        <LazyLoad once>
          <CustomerHome />
        </LazyLoad>

        <LazyLoad once>
          <FeedbackHome />
        </LazyLoad>
        {/* <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
        <div className="elfsight-app-b4540ff0-ac3f-49ff-a524-72a38a1e016d"></div> */}
        <LazyLoad once>
          <ReasonHome />
        </LazyLoad> 

        <LazyLoad once>
          <MediaContextProvider>
            <Media greaterThanOrEqual="md">
              <div className='register-homepage'>
              <RegisterHome />
              </div>
              
            </Media>
          </MediaContextProvider>
        </LazyLoad>

        <LazyLoad once>
         <br></br><br></br><br></br>
          <Guarantee />
        </LazyLoad>
        {/* <LazyLoad once>
          <Footer />
        </LazyLoad> */}

      </div>
    </>
  )

}
