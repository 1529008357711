import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Image from "../../components/image";
import { asset } from '../../helpers/dom.helper';
import { toRoute } from "../../i18n";
import { Media, MediaContextProvider } from "../../media";

const FeaturePlatformHome = React.memo(() => {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <div className="container py-4 pt-5">
      <div className="feature-platform">

        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="text-header">{t('home:Make Things Simpler For Better Living')}</h2>
            <p className="text-h3">{t('home:Make Things Simpler For Better Living 2')}</p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6 text-center">
            <MediaContextProvider>
              <Media lessThan="md">
                <Image src={asset('/images/home/old-platform-mobile.webp')}
                  alt="ระบบบริหารจัดการแบบเก่า ยุ่งยาก ทำงานซับซ้อน" width="310" height="160"
                  className="old-platform img-fluid mb-4" />
              </Media>
            </MediaContextProvider>
            <h3 className="text-menu-title">{t('home:OTHER PLATFORMS')}</h3>
            <p className="text-address mb-0">{t('home:Complicated and redundant')}</p>
            <p className="text-address">{t('home:Complicated and redundant 2')}</p>
            <MediaContextProvider>
              <Media greaterThanOrEqual="md">
                <Image src={asset('/images/home/old-platform-small.webp')}
                  alt="ระบบบริหารจัดการแบบเก่า ยุ่งยาก ทำงานซับซ้อน" width="520" height="260"
                  className="old-platform img-fluid mx-auto" />
              </Media>
            </MediaContextProvider>
          </div>

          <div className="col-md-6 text-center">
            <MediaContextProvider>
              <Media lessThan="md">
                <Image src={asset('/images/home/new-platform-mobile.webp')}
                  alt="ลดขั้นตอน สะดวกรวดเร็ว" width="310" height="160"
                  className="silverman-platform img-fluid my-4" />
              </Media>
            </MediaContextProvider>
            <h3 className="text-menu-title">{t('home:SILVERMAN PLATFORM')}</h3>
            <p className="text-address mb-0">{t('home:Simple and Fast')}</p>
            <p className="text-address">{t('home:Simple and Fast 2')}</p>
            <MediaContextProvider>
              <Media greaterThanOrEqual="md">
                <Image src={asset('/images/home/new-platform-small.webp')}
                  alt="ลดขั้นตอน สะดวกรวดเร็ว" width="520" height="260"
                  className="silverman-platform img-fluid mx-auto" />
              </Media>
            </MediaContextProvider>
          </div>

          <div className="col-12 text-center my-4">
            <Link to={toRoute('/for-resident')}>
              <button className="btn btn-primary text-menu-subtitle">
                {t('Read More')}
                <Image
                  src={asset('/icon/arrow-right.svg')}
                  alt="arrow-right" width="24" height="14" className="arrow" />
              </button>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )

})

export default FeaturePlatformHome
