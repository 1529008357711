import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { Media, MediaContextProvider } from '../../media';
import { Link } from "react-router-dom";
import './homeSilde.scss';

 const HeaderHomeSilde = React.memo(() => {

    const { t } = useTranslation(['translation', 'home'])

    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay:true,
        autoplaySpeed:5000,
        pauseOnHover:false
    };
    
    return (
      <div id="header-home-silde">
        <MediaContextProvider>
        <Slider {...settings}>
          <div >
            <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" style={{background: `url(${asset('/images/homeSilde/Home.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
                    <p className="w-50 pt-3 pb-3" style={{margin:'auto'}} dangerouslySetInnerHTML={{ __html: t('home:Connect seamlessly from Property Developers, Juristic, Committee, Property Management Company, Accountants, Engineers, Property Managers, Security Guards, Maids to Co-owners, Members, Residents, and Tenants. Including Real Estate Agents such as Buyers, Sellers, Lessors, and Lessees, all in one comprehensive platform!') }}/>
                    <div className="app-download d-flex align-items-center justify-content-center">
                    <div className="pr-3">
                        <a href="https://play.google.com/store/apps/details?id=com.port80.silverman&hl=en&gl=US" target="_blank" rel="noopener noreferrer" className="">
                            <Image src={asset('/images/homeSilde/googleplay.png')} alt="silverman app dowload" width="205" height="76" className="d-block w-auto" />
                         </a>
                    </div>
                    <div>
                        <a href="https://apps.apple.com/th/app/silverman/id1436707310" target="_blank" rel="noopener noreferrer" className="">
                            <Image src={asset('/images/homeSilde/appstore.png')} alt="silverman app dowload" width="205" height="76" className="d-block w-auto" />
                        </a>
                    </div>

                   
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
                    <p className="w-75 pt-3" style={{margin:'auto'}}  dangerouslySetInnerHTML={{ __html: t('home:Connect seamlessly from Property Developers, Juristic, Committee, Property Management Company, Accountants, Engineers, Property Managers, Security Guards, Maids to Co-owners, Members, Residents, and Tenants. Including Real Estate Agents such as Buyers, Sellers, Lessors, and Lessees, all in one comprehensive platform!') }} />
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-silde-mobile.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div> 
          <div>
          <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" style={{background: `url(${asset('/images/homeSilde/Accounting_03.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:The accounting statements you see...<br/>might not be as you expect.') }}/>
                    <p className="w-75 pt-3 pb-3" style={{margin:'auto'}} dangerouslySetInnerHTML={{ __html: t(`home:Don't believe anyone until you've tried Silverman property management solution. And most importantly, <br/>don't hesitate, don't wait. Make the decision to start using our software today.<br/>If you don't want your projects to face such a situation in the future, take action now.<br/>Join us on the path to a different and better financial future for your project!<br/>`) }} />
                    <div className="app-download d-flex align-items-center justify-content-center pt-4">
                        <div className="pr-4">
                            <Link to="/register">
								<button className="btn btn-primary">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
								</button>
							</Link>
                        </div>
                        <div>
                            <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light-white">
                                <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:The accounting statements you see...<br/>might not be as you expect.')}}/>               
                <p className="w-75 pt-2" style={{margin:'auto'}} dangerouslySetInnerHTML={{__html:t(`home:Don't believe anyone until you've tried Silverman property management solution. And most importantly, <br/>don't hesitate, don't wait. Make the decision to start using our software today.<br/>If you don't want your projects to face such a situation in the future, take action now.<br/>Join us on the path to a different and better financial future for your project!<br/>`)}} />
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-acounting-mobile-2-new.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div>
          <div>
          <Media greaterThanOrEqual="md">
                <div className="pt-5 pb-5 align-items-left justify-content-left" style={{background: `url(${asset('/images/homeSilde/Accounting_02.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                <div className="offset-md-1 ">
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:When you seek transparency,<br/>reliability, and enduring excellence<br/>in professional accounting.')}}/>
                <p className="pt-3 pb-3" dangerouslySetInnerHTML={{ __html: t(`home:and comprehensive auditing services for your juristic and property management, there's nothing<br/> quite like what we offer! Our services come with a guarantee of sustainability and lasting value<br/> for your projects. Trustworthy and backed by expertise, we are dedicated to providing exceptional<br/> service. Rest assured, Silverman, we are your steadfast companion on the path to your success!`) }}/>
                <div className="d-flex align-items-left justify-content-left pt-4 ">
                        <div className="pr-4">
                            <Link to="/register">
								<button className="btn btn-primary">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
								</button>
							</Link>
                        </div>
                        <div>
                            <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:When you seek transparency,<br/>reliability, and enduring excellence<br/>in professional accounting.')}}/>
                <p className="pt-3 pb-3 text-left" dangerouslySetInnerHTML={{ __html: t(`home:and comprehensive auditing services for your juristic and property management, there's nothing<br/> quite like what we offer! Our services come with a guarantee of sustainability and lasting value<br/> for your projects. Trustworthy and backed by expertise, we are dedicated to providing exceptional<br/> service. Rest assured, Silverman, we are your steadfast companion on the path to your success!`) }}/>
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-acounting-mobile-3.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div>
          
        </Slider>
        
        </MediaContextProvider>
      </div>
     
    );
})
    
export default HeaderHomeSilde