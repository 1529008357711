import React, { useState , useEffect} from "react";
import { Modal} from 'react-bootstrap';
import { asset } from '../../helpers/dom.helper';
import moment from "moment";
import './popup.scss';

export default function PopupBanner() {

    const [visited, setVisible] = useState(false)

    useEffect(()=>{
        var pop_status = localStorage.getItem('pop_status');
        var today = new Date()
  
        if(!pop_status){
          setVisible(true);
          localStorage.setItem('pop_status',1);
          localStorage.setItem('pop_date_start',today)
        }
  
        if(pop_status){
          checkPopupStatus()
        }
   
      },[])
  
      const checkPopupStatus = () => {
          var today = new Date()
          var pop_date_start = localStorage.getItem('pop_date_start');
          var now = moment();
          var todayDate = now.date(); 
          var pop_date_start_date = new Date(pop_date_start).getDate()
    
          if(todayDate > pop_date_start_date){
              localStorage.setItem('pop_date_start',today)
              setVisible(true)
          }else{
              setVisible(false)
          }
      }

    return (
        <>
            <Modal show={visited}  id="modal"  size="lg" aria-labelledby="contained-modal-title-vcenter"  centered 
              className='modal-popup-banner'>
                <div style={{position:'revert'}} className="text-center">  
                    <a href="/seminar" >   
                        <img src={asset('/images/popup/seminar2-popup.webp')}
                        alt="alert" className="img-fluid" style={{maxWidth:'80%'}}/>
                    </a>        
                    <img onClick={() => setVisible(false) }
                        src={asset('/images/banner/Union.png')}
                        alt="alert" style={{width:'20px',position:'absolute',right:'15%',top:'4%',cursor:'pointer'}}/> 
                </div>
            </Modal>
       </>
    );
  
}

