import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { toRoute } from "../../i18n";

const CustomerHome = React.memo(() => {
  const { t } = useTranslation(['translation', 'customer'])

  return (
    <div className="customer">
      <div className="container">

        <h2 className="text-header text-center mb-4 mb-md-0">{t("home:Our Clients")}</h2>
        <p className="text-address text-center mb-4 d-none d-md-block" dangerouslySetInnerHTML={{
          __html: t("home:Silverman's outstanding technology is trusted by a number of leading " +
            "developers and property managements.")
        }} />

        <div className="d-flex flex-wrap justify-content-center total mb-3 mb-md-5">
          <div className="box">
            <Image src={asset('/images/home/customer-project.svg')} alt="จำนวนโครงการ" width="40" height="40" />
            <div className="text-center text-md-left">
              <h6 className="text-primary">700+</h6>
              <p>{t("home:Properties")}</p>
            </div>
          </div>
          <div className="box">
            <Image src={asset('/images/home/customer-unit.svg')} alt="จำนวนยูนิต" width="40" height="40" />
            <div className="text-center text-md-left">
              <h6 className="text-primary">200,000+</h6>
              <p>{t("home:Units")}</p>
            </div>
          </div>
          <div className="box">
            <Image src={asset('/images/home/customer-invoice.svg')} alt="บริหารพื้นที่" width="40" height="40" />
            <div className="text-center text-md-left">
              <h6 className="text-danger">6,000 ลบ.+</h6>
              <p>{t("เปิดใบแจ้งหนี้")}</p>
            </div>
          </div>
          <div className="box">
            <Image src={asset('/images/home/customer-location.svg')} alt="บริหารพื้นที่" width="40" height="40" />
            <div className="text-center text-md-left">
              <h6 className="text-warning">2.5 ล้านตร.ม.+</h6>
              <p>{t("บริหารพื้นที่")}</p>
            </div>
          </div>
          <div className="box">
            <Image src={asset('/images/home/customer-manage.svg')} alt="บริหารพื้นที่" width="40" height="40" />
            <div className="text-center text-md-left">
              <h6 className="text-success">100 +</h6>
              <p>{t("บริษัทบริหารนิติ")}</p>
            </div>
          </div>
        </div>

        <div className="container-fluid text-center">
          <div className="row no-gutters align-items-center mb-3 mb-md-0">
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/singha_estate.webp')}
              alt="singha estate" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/pruksa.webp')}
              alt="pruksa" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/supalai.webp')}
              alt="supalai" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/land-and-houses.webp')}
              alt="land and houses" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/sansiri.webp')}
              alt="sansiri" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/sena.webp')}
              alt="sena" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/q-house.webp')}
              alt="q-house" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/real_asset.webp')}
              alt="real asset" width="120" height="36" /></div>
            <div className="col"><Image className="img-fluid"
              src={asset('/images/home/project/britania.webp')}
              alt="britania" width="120" height="36" /></div>
          </div>

          <hr className="w-100 mt-0 d-none d-md-block" />

          <div className="row align-items-center justify-content-center mb-md-5">
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/bless_asset.webp')}
              alt="bless asset" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/property/stms.webp')}
              alt="stms" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/property/bm-group.webp')}
              alt="bm-group" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/property/first-pacific.webp')}
              alt="first pacific harrison" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/savills.webp')}
              alt="pmc" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/victory.webp')}
              alt="gk property" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/fast.webp')}
              alt="proactive" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/property/cbm.webp')}
              alt="cbm" width="120" height="36" /></div>
            <div className="col-2 col-md mb-3"><Image className="img-fluid logo"
              src={asset('/images/home/project/property/vertiplus.webp')}
              alt="vertiplus" width="120" height="36" /></div>
          </div>

          <Link to={toRoute("/customer")} className="d-none d-md-block">
            <button className="btn btn-primary text-menu-subtitle mb-4">
              {t('Read More')}
              <Image src={asset('/icon/arrow-right.svg')} alt="arrow-right" width="24" height="14" className="arrow" />
            </button>
          </Link>
        </div>

      </div>
    </div>
  )
})

export default CustomerHome