import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { toRoute } from "../../i18n";

const FeatureList = React.memo(() => {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <div className="feature-list">
      <div className="feature-application mb-4">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{ __html: t('home:Silverman, make life easier') }} className="text-header d-md-none mb-4" />

          <div className="row">
            <div className="col-12 col-md-7 text-center">
              <Image src={asset('/images/home/feature-app-mobile.webp')} desktop={asset('/images/home/feature-app-desktop-small.webp')} alt="Feature application" width="670" height="470" className="w-75 h-auto mb-4" />
            </div>
            <div className="col-12 col-md-5">
              <h2 dangerouslySetInnerHTML={{ __html: t('home:Silverman, make life easier') }} className="text-header d-none d-md-block mt-5 mb-4" />
              <p className="text-address mb-4">
                {t('home:Contacting property management, requesting for repairs, filing a complaint, and getting notification for news and parcel. Get things done in just one click.')}
              </p>
              <Link to={toRoute("/for-resident")} className="text-menu-subtitle text-primary">
                {t('Read More')}
                <Image src={asset('/icon/arrow-right-blue.svg')} alt="arrow right blue" width="24" height="14" className="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* end feature app */}

      <div className="feature-website">
        <div className="container">
          <h2 dangerouslySetInnerHTML={{ __html: t('home:Take property management to the next level') }} className="text-header d-md-none mb-4 text-center text-md-left" />

          <div className="row">
            <div className="col-12 col-md-5 order-2 order-md-1">
              <h2 dangerouslySetInnerHTML={{ __html: t('home:Take property management to the next level') }} className="text-header d-none d-md-block mb-4" />
              <p className="text-address mb-4">
                {t('home:Silverman provides full-featured and comprehensive solution, including monitoring all aspects of work from one simple Dashboard, connecting with residents via private chat feature, and managing multiple properties, all run from one application.')}
              </p>
              <Link to={toRoute("/for-juristic-management")} className="text-menu-subtitle text-primary">
                {t('Read More')}
                <Image src={asset('/icon/arrow-right-blue.svg')} alt="arrow right blue" width="24" height="14" className="arrow" />
              </Link>
            </div>

            <div className="col-12 col-md-7 order-1 order-md-2">
              <Image src={asset('/images/home/feature-web-mobile.webp')} desktop={asset('/images/home/feature-web-desktop-small.webp')} alt="Feature application" width="780" height="440" className="d-block w-100 h-auto mb-4" />
            </div>
          </div>
        </div>
      </div>
      {/* end feature website */}

      <div className="feature-accounting">
        <div className="container">
          <h2 className="text-header text-center mb-4">{t('home:Cover all essential accounting & financial tasks')}</h2>

          <div className="row">
            <div className="col-12">
              <div className="row no-gutters flex-column">
                <div className="col-12 order-1 order-md-2">
                  <Image src={asset('/images/home/feature-acc-mobile.webp')} desktop={asset('/images/home/feature-acc-desktop-small.webp')} alt="Feature application" width="920" height="320" className="d-block w-100 h-auto mb-4" />
                </div>

                <div className="col-12 order-2 order-md-1 text-md-center">
                  <p dangerouslySetInnerHTML={{
                    __html: t('home:Our AI-powered accounting system reduces human error by detecting anomalies, and eases your workload by automatically calculating late fee. Our accounting features include Real Time Accounting Entry, Statement of Financial Position, Accrued Revenue and Expense, and Cash Flow Report.')
                  }} className="text-address mb-4" />
                  <Link to={toRoute("/for-juristic-accounting")} className="d-block d-md-none text-menu-subtitle text-primary mb-4">
                    {t('Read More')}
                    <Image src={asset('/icon/arrow-right-blue.svg')} alt="arrow-right" width="24" height="14" className="arrow" />
                  </Link>

                  <Link to={toRoute("/for-juristic-accounting")} className="d-none d-md-block">
                    <button className="btn btn-primary text-menu-subtitle mb-4">
                      {t('Read More')}
                      <Image src={asset('/icon/arrow-right.svg')} alt="arrow-right" width="24" height="14" className="arrow" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end feature accounting */}
    </div>
  )

})

export default FeatureList