import PropTypes from "prop-types";
import React from "react";
import { Carousel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { Media, MediaContextProvider } from '../../media';

const HeaderHome = React.memo(({ interval }) => {

  const { t } = useTranslation(['translation', 'home'])

  return (
    <div className="header">
      <MediaContextProvider>

      <Media lessThan="md">
        {/* <Image noImage={asset('/images/no-image2.svg')} src={asset('/images/home/header-bg-mobile.webp')} alt="silver header background" width="200" height="480" className="d-block w-100 h-auto" /> */}
        <br></br>
        <div className="container text-center">
          <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />

          <Image src={asset('/images/home/header-desktop-1.webp')} alt="silverman application" width="320" height="270" className="d-block w-100 h-auto mt-4" />

          <p className="text-address" dangerouslySetInnerHTML={{ __html: t('home:Featuring from e-meeting & e-vote online') }} />
        </div>
        </Media>

        {/* <Media lessThan="md">
          <Carousel controls={false} indicators={false}>
            {[0].map((_, idx) => {
              return (
                <Carousel.Item interval={interval} key={idx}>
                  <Image noImage={asset('/images/no-image2.svg')} src={asset('/images/home/header-bg-mobile.webp')} alt="silver header background" width="200" height="480" className="d-block w-100 h-auto" />

                  <Carousel.Caption>
                    <div className="container text-center">
                      <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />

                      <Image src={asset('/images/home/header-mobile.webp')} alt="silverman application" width="320" height="270" className="d-block w-100 h-auto mt-4" />

                      <p className="text-address" dangerouslySetInnerHTML={{ __html: t('home:Featuring from e-meeting & e-vote online') }} />
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Media> */}

        <Media greaterThanOrEqual="md">
        {/* <Image noImage={asset('/images/no-image2.svg')} src={asset('/images/home/header-bg-desktop.webp')} alt="silver header background" width="1920" height="720" className="d-block w-100 h-auto" /> */}
        <br></br><br></br>
        <div className="container h-100 position-relative">
          <div className="row no-gutters align-items-center h-100">
            <div className="col">
              <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
              <p className="text-address" dangerouslySetInnerHTML={{ __html: t('home:Featuring from e-meeting & e-vote online') }} />

              <div className="d-block my-4">
                <div className="app-download">
                  <Image src={asset('/icon/app-download.webp')} alt="silverman app dowload" width="280" height="96" className="d-block w-auto" />

                  <a href="https://play.google.com/store/apps/details?id=com.port80.silverman&hl=en&gl=US" target="_blank" rel="noopener noreferrer" className="stretched-link w-50" />
                  <a href="https://apps.apple.com/th/app/silverman/id1436707310" target="_blank" rel="noopener noreferrer" className="stretched-link w-50" />
                </div>
              </div>
            </div>

            <div className="col-6">
              <Image src={asset('/images/home/header-desktop-1.webp')} alt="silverman application" width="670" height="560" className="d-block h-auto mx-auto" style={{ width: '90%', marginTop: '-3rem' }} />
            </div>
          </div>
        </div>
          {/* <Carousel controls={false} indicators={false}>
            {[0].map((_, idx) => {
              return (
                <Carousel.Item interval={interval} key={idx}>
                  <Image noImage={asset('/images/no-image2.svg')} src={asset('/images/home/header-bg-desktop.webp')} alt="silver header background" width="1920" height="720" className="d-block w-100 h-auto" />

                  <Carousel.Caption>
                    <div className="container h-100 position-relative">
                      <div className="row no-gutters align-items-center h-100">
                        <div className="col">
                          <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
                          <p className="text-address" dangerouslySetInnerHTML={{ __html: t('home:Featuring from e-meeting & e-vote online') }} />

                          <div className="d-block my-4">
                            <div className="app-download">
                              <Image src={asset('/icon/app-download.webp')} alt="silverman app dowload" width="280" height="96" className="d-block w-auto" />

                              <a href="https://play.google.com/store/apps/details?id=com.port80.silverman&hl=en&gl=US" target="_blank" rel="noopener noreferrer" className="stretched-link w-50" />
                              <a href="https://apps.apple.com/th/app/silverman/id1436707310" target="_blank" rel="noopener noreferrer" className="stretched-link w-50" />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <Image src={asset('/images/home/header-desktop.webp')} alt="silverman application" width="670" height="560" className="d-block h-auto mx-auto" style={{ width: '90%', marginTop: '-3rem' }} />
                        </div>
                      </div>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel> */}
        </Media>

      </MediaContextProvider>
    </div>
  )

})

HeaderHome.propTypes = {
  interval: PropTypes.number
}
HeaderHome.defaultProps = {
  interval: 100000
}

export default HeaderHome
